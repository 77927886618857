import React, { useState, useEffect, Fragment } from "react"
import ReactMapGL, { Marker, FlyToInterpolator } from "react-map-gl"
import Poin from "../images/geo.png"

const defaultState = {
  latitude: 37.78,
  longitude: -122.41,
  zoom: 14,
  bearing: 0,
  pitch: 0,
}

const DRC_MAP = {
  zoom: 14.3,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
  transitionEasing: t => t * (2 - t),
}

const Mapa = ({ lat, lng }) => {
  const [viewport, setViewport] = useState(defaultState)

  useEffect(() => {
    if (lat !== null && lng !== null) {
      setViewport(oldViewport => ({
        ...oldViewport,
        ...DRC_MAP,
        longitude: parseFloat(lng),
        latitude: parseFloat(lat),
      }))
    }
  }, [lat, lng])

  return (
    <Fragment>
      {lat && lng ? (
        <ReactMapGL
          {...viewport}
          width="100%"
          height="400px"
          style={{ paddingBottom: "20px" }}
          mapStyle="mapbox://styles/mapbox/dark-v9"
          onViewportChange={nextViewport => setViewport(nextViewport)}
          mapboxApiAccessToken={"pk.eyJ1IjoiY2xhc2lob21lIiwiYSI6ImNsZXVqN2M5dzBrNnMzd3BidjhhenQzOWkifQ.9-WGLYsiyhsikDCT5ESfhQ"}
        >
          <Marker
            latitude={parseFloat(lat)}
            longitude={parseFloat(lng)}
            offsetLeft={-20}
            offsetTop={-10}
          >
            <img src={Poin} width="50px" />
          </Marker>
        </ReactMapGL>
      ) : null}
    </Fragment>
  )
}

export default Mapa
