import React from "react"
import { FaWhatsapp } from "react-icons/fa"

export default ({ title, url, phone, image }) => {
  return (
    <div className="share-networks clearfix">
      <span className="share-label">Compartir</span>
      <span>
        <a
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        >
          <i className="icon-facebook"></i>Facebook
        </a>
      </span>

     {/*  <span>
        <a
          target="_blank"
          href={`https://www.linkedin.com/cws/share?url=${url}`}
        >
          <i className="icon-linkedin"></i>LinkedIn
        </a>
      </span> */}

      <span>
        <a
          target="_blank"
          href={`https://api.whatsapp.com/send?phone=${phone}&text=Hola%20estoy%20interesado%20por%20esta%20propiedad:%20${url}`}
        >
          <FaWhatsapp className="icon-linkedin" />
          WhatsApp
        </a>
      </span>
    </div>
  )
}
