import React, { useEffect, useState, Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import Share from "../components/share"
import Calculator from "../components/calculator"

import Logo from "../images/logoblackfin.png"

import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import FilterProperties from "../components/properties/FilterProperties"
import BannerTop from "../components/bannerTop"
import Mapa from "../components/mapa"
import { Link } from "gatsby"
import FormatCurrency from "../utils/format-currency"
import { FaUser } from "react-icons/fa"
import FeaturedProperties from "../components/properties/FeaturedProperties"

const defaultState = {
  imagenes: [],
  thumbs: [],
}

const DetallePropiedad = props => {
  const [property, setProperty] = useState(defaultState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [pathname, setPathname] = useState("")

  useEffect(() => {
    let search = props.location.search
    let params = new URLSearchParams(search)
    console.log(params)
    let id = params.get("id")

    const fetchData = async () => {
      setLoading(true)
      setError(false)
      try {
        const result = await axios
          .get(`https://api.clasihome.com/rest/properties?propertyId=${id}`)
          .then(r => {
            if (!r.data) {
              setError(true)
            } else {
              setProperty(r.data)
            }
          })
      } catch (e) {
        setError(true)
        console.log("eror")
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    setPathname(window.location.href)
  }, [])

  function createMarkup(descripcion) {
    return {
      __html: `<div style="font-color: #8b9293">${descripcion}</div>  <div className="common-note" style="padding: 20px; border: 1px solid #e2e2e2; margin-top: 20px">
      <h4 className="common-note-heading">Notas:</h4>
      <p>
        Si no encuentra la propiedad que busca, nuestros Agentes
        Inmobiliarios están atentos a recibir sus consultas. Lo
        asesoramos y gestionamos su crédito para que tome la mejor
        decisión de compra.
      </p>
    </div>`,
    }
  }

  return (
    <Layout>
      <SEO description={`Tenemos la propiedad para ti!`} pathname={pathname} />
      {error && (
        <Fragment>
          <div className="container contents lisitng-grid-layout">
            <div className="row">
              <div className="span12 main-wrap">
                <div className="main">
                  {" "}
                  <div className="inner-wrapper">
                    <article
                      className="page type-page clearfix"
                      style={{
                        fontSize: "20px",
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      <p>
                        <strong>No se encontró ninguna propiedad</strong>
                      </p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {loading ? (
        <Fragment>
          <div className="container contents lisitng-grid-layout">
            <div className="row">
              <div className="span12 main-wrap">
                <div className="main">
                  {" "}
                  <div className="inner-wrapper">
                    <article
                      className="page type-page clearfix"
                      style={{
                        fontSize: "20px",
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      <p>
                        <strong>Cargando...</strong>
                      </p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}

      {!loading && !error ? (
        <Fragment>
          <BannerTop
            title={property.title}
            subtitle={"Ficha de la Propiedad"}
          />
          <div className="container contents lisitng-grid-layout">
            <div className="row">
              <div className="span9 main-wrap">
                <div className="main">
                  <section id="overview">
                    <ImageGallery
                      items={property.images.map((e, index) => {
                        return {
                          original: e.url,
                          thumbnail: e.url,
                        }
                      })}
                      thumbnailPosition="right"
                      autoPlay={true}
                      showNav={false}
                    />

                    <article className="property-item clearfix">
                      <div className="wrap clearfix">
                        <h4 className="title">
                          CÓDIGO: {property.code.replace("AGE", "")}
                        </h4>
                        <h5 className="price">
                          <span className="status-label">
                            {property.currency === "CLP"
                              ? "$"
                              : property.currency}{" "}
                            {FormatCurrency(property.currency, property.value)}
                          </span>
                          <span>
                            {property.propertyType == "PANO"
                              ? "PAÑO INMOBILARIO"
                              : property.propertyType}{" "}
                            EN {property.operation}{" "}
                            {/*  <small> - {property.ubication.address}</small> */}
                          </span>
                        </h5>
                      </div>

                      <div className="property-meta clearfix">
                        {property.characteristics.filter(
                          c => c.name === "Superficie total"
                        ).length > 0 && (
                          <span>
                            <i className="icon-area"></i>
                            {
                              property.characteristics.filter(
                                c => c.name === "Superficie total"
                              )[0].value
                            }{" "}
                            mt2 tot.
                          </span>
                        )}

                        {property.characteristics.filter(
                          c => c.name === "Superficie útil"
                        ).length > 0 && (
                          <span>
                            <i className="icon-area"></i>
                            {
                              property.characteristics.filter(
                                c => c.name === "Superficie útil"
                              )[0].value
                            }{" "}
                            mt2 const.
                          </span>
                        )}

                        {property.characteristics.filter(
                          c => c.name === "Habitaciones"
                        ).length > 0 && (
                          <span>
                            <i className="icon-bed"></i>
                            {
                              property.characteristics.filter(
                                c => c.name === "Habitaciones"
                              )[0].value
                            }
                            &nbsp;Hab.
                          </span>
                        )}
                        {property.characteristics.filter(
                          c => c.name === "Baños"
                        ).length > 0 && (
                          <span>
                            <i className="icon-bath"></i>
                            {
                              property.characteristics.filter(
                                c => c.name === "Baños"
                              )[0].value
                            }
                            &nbsp;Baños
                          </span>
                        )}

                        {property.characteristics.filter(
                          c => c.name === "Estacionamientos"
                        ).length > 0 && (
                          <span>
                            <i className="icon-garage"></i>
                            {
                              property.characteristics.filter(
                                c => c.name === "Estacionamientos"
                              )[0].value
                            }
                            &nbsp;Est.
                          </span>
                        )}

                        {property.characteristics.filter(
                          c => c.name === "Bodega"
                        ).length > 0 && (
                          <span>
                            <i className="icon-area"></i>
                            Bodega
                          </span>
                        )}

                        <span className="printer-icon">
                          <a href="javascript:window.print()">Imprimir</a>
                        </span>
                      </div>
                      <div></div>
                      <div
                        className="content clearfix"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {property.publicObservations}
                      </div>
                      <div style={{ margin: "0px 20px" }}>
                        <Link
                           to={`consulta?id=${property.code}&nameAgent=${property._comercialUser_person[0].firstName}%20${property._comercialUser_person[0].lastName}&eAgent=${property._comercialUser[0].email}`}
                          class="btn-grey btn-mini"
                          style={{
                            backgroundColor: "#464646",
                            marginRight: "10px",
                          }}
                        >
                          Consulta
                        </Link>
                        <Link
                          to={`orden-visita?id=${property.code}&nameAgent=${property._comercialUser_person[0].firstName}%20${property._comercialUser_person[0].lastName}&eAgent=${property._comercialUser[0].email}`}
                          class="btn-grey btn-mini"
                          style={{
                            backgroundColor: "#464646",
                          }}
                        >
                          Orden de visita
                        </Link>
                      </div>
                      <Share
                        title={property.title}
                        url={`https://www.agenteinmobiliario.cl/detalle-propiedad/?id=${property._id}`}
                        phone={`+569${property._comercialUser_person[0].phone.phoneNumber}`}
                      />
                      {property.characteristics.length > 0 && (
                        <div className="features">
                          <h4 className="title">Características:</h4>
                          <ul className="arrow-bullet-list clearfix">
                            <Fragment>
                              {property.characteristics.map((c, index) => (
                                <li key={index}>
                                  {typeof c.value === "boolean" ? (
                                    <a href="#">{c.name}</a>
                                  ) : (
                                    <a href="#">
                                      {c.name}: {c.value}
                                    </a>
                                  )}
                                </li>
                              ))}
                            </Fragment>
                          </ul>
                        </div>
                      )}
                    </article>

                    {property.videos.length > 0 && (
                      <div className="property-video">
                        {property.videos[0].provider === "YOUTUBE" && (
                          <iframe
                            width="100%"
                            height="451"
                            src={property.videos[0].url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        )}
                        {property.videos[0].provider === "VIMEO" && (
                          <iframe
                            src={property.videos[0].url}
                            width="100%"
                            height="451"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                          ></iframe>
                        )}
                      </div>
                    )}

                    <div className="map-wrap clearfix">
                      <span className="map-label">Ubicación</span>
                      <Mapa
                        lat={property.ubication.location.coordinates[1]}
                        lng={property.ubication.location.coordinates[0]}
                      />
                    </div>
                  </section>
                </div>
              </div>
              <div className="span3 sidebar-wrap">
                <aside className="sidebar">
                  {/* <section className="widget">
                    <h3 className="title" style={{ textAlign: "center" }}>
                      {" "}
                      {property._comercialUser_person[0].firstName}{" "}
                      {property._comercialUser_person[0].lastName}
                    </h3>
                    <div className="agent-info" style={{ textAlign: "center" }}>
                      <img
                        width="210"
                        height="210"
                        style={{ background: "#000", padding: "10px" }}
                        src={
                          property._comercialUser[0].avatar
                            ? property._comercialUser[0].avatar
                            : Logo
                        }
                        className="attachment-agent-image wp-post-image"
                        alt="pilar"
                      />

                      <ul
                        className="contacts-list"
                        style={{
                          paddingBottom: "20px",
                          textAlign: "left",
                        }}
                      >
                        <br />
                        {property._comercialUser_person[0].mobile && (
                          <li>
                            <i className="icon-phone"></i> Celular : +56 9{" "}
                            {
                              property._comercialUser_person[0].mobile
                                .phoneNumber
                            }
                          </li>
                        )}
                      </ul>
                      <p>
                        Agente Senior Inmobiliario
                        <br />
                        {property._office[0].email}
                      </p>
                    </div>
                  </section> */}
                  <section className="widget advance-search">
                    {property.operation === "VENTA" && <Calculator />}

                    <h4 className="title search-heading">
                      Buscar Propiedad<i className="icon-search"></i>
                    </h4>
                    <div className="as-form-wrap">
                      <FilterProperties />
                    </div>
                  </section>
                </aside>
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Layout>
  )
}

export default DetallePropiedad
